import { useAuthenticator } from '@aws-amplify/ui-react'
import { useMemo } from 'react'

import { useGetUserQuery } from '~/data/api/client'
import type { SynopUser } from '~/data/types/user'

type MaybeSynopUser = SynopUser | undefined

export function useUser(userId?: string) {
  const result = useGetUserQuery({ id: userId ?? '' }, { skip: !userId })

  // Ensure the user returned from the hook matches the ID passed in. This is needed when the ID
  // changes or is cleared.
  const user = (result.data?.id === userId ? result.data : undefined) as MaybeSynopUser
  return { user, ...result }
}

export function useCurrentUser() {
  const { user } = useAuthenticator((context) => [context.user])
  const { user: currentUser, isLoading: isUserLoading } = useUser(user.username)

  const isAdmin = useMemo(() => {
    return currentUser?.roles?.includes('Admin')
  }, [currentUser])

  return {
    user,
    synopUser: currentUser,
    isUserLoading,
    isAdmin
  }
}
