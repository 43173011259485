import type { RumInitConfiguration } from '@datadog/browser-rum'

export default function getDatadogConfig(env: string, appVersion: string): RumInitConfiguration {
  return {
    applicationId: 'b3111eba-7bb2-4e72-8aad-8bef47e230e8',
    clientToken: 'pub13634e6242ddf896c175cfd2d2f57b23',
    site: 'datadoghq.com',
    service: 'synop-next',
    env,

    // Specify a version number to identify the deployed version of your application in Datadog
    version: appVersion,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'allow',
    startSessionReplayRecordingManually: true
  }
}
