/**
 * Exports a logger object which can be used to log to the browser console. In production, nothing
 * will be logged. In other environments (including local development), the log level can be configured
 * by setting the `NEXT_PUBLIC_LOG_LEVEL` environment variable to one of `info`, `warn`, or `error`. If
 * no value is provided, the default is `info`. Usages are safe to commit.
 */
import { useMemo } from 'react'

type LogFn = (...args: unknown[]) => void
type LogLevel = 'info' | 'warn' | 'error' | 'off'

// The global log level
const logLevel: LogLevel = (() => {
  if (process.env.NODE_ENV === 'production') return 'off'

  const envVar = process.env.NEXT_PUBLIC_LOG_LEVEL ?? 'info'
  if (envVar === 'off' || envVar === 'error' || envVar === 'warn' || envVar === 'info') return envVar

  // Received some unrecognized value, so default to 'off'
  console.warn(`Unrecognized log level: "${envVar}", defaulting to "off"`)
  return 'off'
})()

function shouldLog(level: Exclude<LogLevel, 'off'>): boolean {
  if (logLevel === 'off') return false

  switch (level) {
    case 'info':
      return logLevel === 'info'
    case 'warn':
      return logLevel !== 'error'
    case 'error':
      return true
  }
}

class ConsoleLogger {
  private name: string

  constructor(name: string = 'Logger') {
    this.name = name
  }

  private get prefix() {
    return `[${this.name}]`
  }

  private logFnFactory(level: Exclude<LogLevel, 'off'>): LogFn {
    return (...args) => {
      if (shouldLog(level)) {
        console[level](this.prefix, ...args)
      }
    }
  }

  public readonly info = this.logFnFactory('info')
  public readonly warn = this.logFnFactory('warn')
  public readonly error = this.logFnFactory('error')
}

// Non-hook export for use outside of hooks/components
export const logger = new ConsoleLogger()

export default function useLogger(name?: string): ConsoleLogger {
  return useMemo(() => new ConsoleLogger(name), [name])
}
