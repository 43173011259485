import type { BaseQueryApi } from '@reduxjs/toolkit/dist/query/baseQueryTypes'
import type { MaybePromise } from '@reduxjs/toolkit/dist/query/tsHelpers'
import { fetchAuthSession } from 'aws-amplify/auth'

import { logger } from '~/debug/logger'

type PrepareHeadersFn = (
  headers: Headers,
  api: Pick<BaseQueryApi, 'getState' | 'extra' | 'endpoint' | 'type' | 'forced'>
) => MaybePromise<Headers>

const prepareAuthHeader: PrepareHeadersFn = (headers) =>
  fetchAuthSession().then(
    ({ tokens }) => {
      const accessToken = tokens?.accessToken.toString()
      headers.set('authorization', `Bearer ${accessToken}`)
      return headers
    },
    () => {
      logger.info('Unable to resolve auth token for API Headers')
      return headers
    }
  )

export default prepareAuthHeader
