import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import envConfig from '~/configs/env'

import prepareAuthHeader from './headers'

const { apiUrl } = envConfig
const baseQuery = fetchBaseQuery({
  baseUrl: apiUrl,
  prepareHeaders: prepareAuthHeader
})

export type BaseQuery = typeof baseQuery
export const emptyRootAPI = createApi({
  reducerPath: 'api',
  baseQuery,
  endpoints: () => ({})
})
