import type { RumInitConfiguration } from '@datadog/browser-rum'

import getAmplifyConfig from './amplify'
import getDatadogConfig from './datadog'
import './i18n'

type AppEnvironment = 'beta' | 'gamma' | 'prod' | 'prod_eu'

export interface Config {
  appVersion: string
  environment: AppEnvironment
  rollbarClientAccessToken: string
  rollbarEnv: string
  apiUrl: string
  ocppWebsocketUrl: string
  datadog: RumInitConfiguration | null
  supportedBrowsersRegex?: RegExp

  // Amplify itself sets this as 'any' vs. giving us a type to use
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  amplify: any
}

const appVersion = process.env.NEXT_PUBLIC_APP_VERSION ?? ''
const environment = process.env.NEXT_PUBLIC_ENV_NAME

if (!environment) {
  throw new Error('`NEXT_PUBLIC_ENV_NAME` environment variable not set. Add it to your `.env.local` file.')
} else if (environment !== 'prod_eu' && environment !== 'prod' && environment !== 'gamma') {
  throw new Error(
    `Invalid environment: "${environment}". Must be one of: "prod", "gamma" or "beta". ` +
      'Check your `.env.local` file.'
  )
}

const configs = {
  gamma: {
    apiUrl: 'https://9lykjw9zli.execute-api.us-east-1.amazonaws.com/gamma',
    datadog: getDatadogConfig('gamma', appVersion),
    ocppWebsocketUrl: 'chargers.gamma.synop.ai/charger',
    amplify: getAmplifyConfig({
      awsCognitoRegion: 'us-east-1',
      awsProjectRegion: 'us-east-1',
      awsUserPoolsId: 'us-east-1_n6ZExRe7q',
      awsUserPoolsWebClientId: '6qg1ucqdedhd6vbh2j3i6f6qga',
      oauthDomain: 'synop-dev.auth.us-east-1.amazoncognito.com'
    })
  },
  prod: {
    apiUrl: 'https://2ufjtpakn2.execute-api.us-east-1.amazonaws.com/prod',
    datadog: getDatadogConfig('prod', appVersion),
    ocppWebsocketUrl: 'chargers.synop.ai/charger',
    amplify: getAmplifyConfig({
      awsCognitoRegion: 'us-east-1',
      awsProjectRegion: 'us-east-1',
      awsUserPoolsId: 'us-east-1_XWvky1XKq',
      awsUserPoolsWebClientId: '5bps4ed1vu0k99bsjses7r1fa8',
      oauthDomain: 'synop-prod.auth.us-east-1.amazoncognito.com'
    })
  },
  prod_eu: {
    apiUrl: 'https://w1fcqlqbg5.execute-api.eu-west-1.amazonaws.com/prod-eu',
    datadog: null,
    ocppWebsocketUrl: 'chargers.eu.synop.ai/charger',
    amplify: getAmplifyConfig({
      awsCognitoRegion: 'eu-west-1',
      awsProjectRegion: 'eu-west-1',
      awsUserPoolsId: 'eu-west-1_bRC37y2te',
      awsUserPoolsWebClientId: '756rphi5f6m82aptdken8hmpo2',
      oauthDomain: 'synop-prod.auth.eu-west-1.amazoncognito.com'
    })
  }
}

function compileSupportedBrowsersRegex() {
  const supportedBrowsers = process.env.NEXT_PUBLIC_SUPPORTED_BROWSERS_REGEX
  if (!supportedBrowsers) return undefined

  try {
    return new RegExp(supportedBrowsers)
  } catch (e) {
    return undefined
  }
}

const envConfig: Config = {
  appVersion,
  environment,
  rollbarClientAccessToken: process.env.NEXT_PUBLIC_ROLLBAR_CLIENT_ACCESS_TOKEN ?? '',
  rollbarEnv: process.env.NEXT_PUBLIC_ROLLBAR_ENVIRONMENT ?? '',
  supportedBrowsersRegex: compileSupportedBrowsersRegex(),
  ...configs[environment]
}

export default envConfig
